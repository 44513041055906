import { render, staticRenderFns } from "./ScreenshotGalery.vue?vue&type=template&id=793f4f99&scoped=true&"
import script from "./ScreenshotGalery.vue?vue&type=script&lang=js&"
export * from "./ScreenshotGalery.vue?vue&type=script&lang=js&"
import style0 from "./ScreenshotGalery.vue?vue&type=style&index=0&id=793f4f99&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793f4f99",
  null
  
)

export default component.exports
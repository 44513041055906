<template>
  <div :style="getBackgroundStyleImage(backgroundImage)" class="header-image">
    <div class="header-image_logo">
      <img :src="logoImage" />
    </div>
    <div class="header-image_eyecatcher">
      <img :src="eyecatcherImage" />
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderImage",
  props: ["backgroundImage", "logoImage", "eyecatcherImage"],
  methods: {
    getBackgroundStyleImage: function(image) {
      return "background-image: url('" + image + "');";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-image_logo img {
  width: 100%;
}

.header-image_logo {
  width: 900px;
  margin: 0 auto;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
}

.header-image_eyecatcher {
  width: 100%;
  height: auto;
  display: none;
}

.header-image {
  width: 100%;
  height: 800px;
  background-size: cover;
  background-position: bottom;
  position: relative;
}

/* Extra small devices (phones, 600px and down) */
/*@media only screen and (max-width: 767px) {
  .header-image_logo {
    width: 320px;
    left: 50%;
    top: 111px;
    position: absolute;
    transform: translateX(-50%);
  }

  .header-image_eyecatcher {
    width: 102px;
    position: absolute;
    top: 210px;
    left: 50%;
    transform: translateX(-50%);
  }

  .header-image {
    width: 100%;
    height: 400px;
    background-size: cover;
    position: relative;
  }
}*/

/* Medium devices (landscape tablets, 768px and up) */
/*@media only screen and (min-width: 768px) {
  .header-image {
    width: 100%;
    height: 500px;
    background-size: cover;
    position: relative;
  }

  .header-image_logo {
    width: 352px;
    left: 15px;
    top: 328px;
    position: absolute;
  }

  .header-image_eyecatcher {
    width: 196px;
    height: 660px;
    position: absolute;
    top: 71px;
    right: 76px;
  }
}*/

/*Large devices (laptops/desktops, 992px and up) */
/*@media only screen and (min-width: 992px) {
  .header-image {
    width: 100%;
    height: 600px;
    background-size: cover;
    position: relative;
  }

  .header-image_logo {
    width: 570px;
    height: 300px;
    left: 15px;
    top: 337px;
    position: absolute;
  }

  .header-image_eyecatcher {
    width: 196px;
    height: 660px;
    position: absolute;
    top: 181px;
    right: 126px;
  }
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/*@media only screen and (min-width: 1200px) {
  .header-image {
    width: 100%;
    height: 600px;
    background-size: cover;
    position: relative;
  }

  .header-image_logo {
    width: 570px;
    height: 300px;
    left: -5px;
    top: 337px;
    position: absolute;
  }

  .header-image_eyecatcher {
    width: 196px;
    height: 660px;
    position: absolute;
    top: 181px;
    right: 126px;
  }
}*/

/* Extra large devices (large laptops and desktops, 1200px and up) */
/*@media only screen and (min-width: 1440px) {
  .header-image {
    width: 100%;
    height: 600px;
    background-size: cover;
    position: relative;
  }

  .header-image_logo {
    width: 570px;
    height: 300px;
    left: 9px;
    top: 330px;
    position: absolute;
  }

  .header-image_eyecatcher {
    width: 196px;
    height: 660px;
    position: absolute;
    top: 181px;
    right: 126px;
  }
}*/
</style>

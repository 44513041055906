<template>
    <div :class="{'content-teaser': true, 'clearfix': true, 'right': float == 'right', 'left': float == 'left'}">
        <div class="content-teaser_image" v-if="image">
            <img :src="image" />
        </div>
        <div class="content-teaser_content">
            <h3 class="content-teaser_headline" v-html="headline"></h3>
            <p class="content-teaser_text" v-html="text"></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContentSection',
        props: ['headline', 'text', 'image', 'float'],
        methods: {
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 767px) {
        .content-teaser {
            margin-bottom: 60px;
            width: 100%;
            position: relative;
            background: #FFF;
            padding: 30px 20px;
            box-shadow: 0px 3px 6px #00000029;
        }

        .content-teaser_image {
            width: 100%;
            text-align: center;
            transform: translateY(-50px);
        }

            .content-teaser_image img {
                height: 280px;
                width: auto;
                display: inline-block;
            }

        .content-teaser_content {
            width: 100%;
        }

        .content-teaser_headline {
            font-size: 28px;
            margin: 0 0 20px 0;
        }

        .content-teaser_text {
            font-size: 16px;
            line-height: 24px;
            margin: 0;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        .content-teaser {
            margin-bottom: 60px;
            width: 100%;
            position: relative;
            background: #FFF;
            padding: 30px 20px;
            box-shadow: 0px 3px 6px #00000029;
        }

            .content-teaser.left .content-teaser_image {
                left: 0;
            }

            .content-teaser.right .content-teaser_image {
                right: 0;
            }

            .content-teaser.left .content-teaser_content {
                float: right;
            }

            .content-teaser.right .content-teaser_content {
                float: left;
            }

        .content-teaser_image {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding: 0 25%;
        }

            .content-teaser_image img {
                height: 340px;
                width: auto;
            }

        .content-teaser.right .content-teaser_image img {
            transform: translateX(50%);
        }

        .content-teaser.left .content-teaser_image img {
            transform: translateX(-50%);
        }

        .content-teaser_content {
            width: 50%;
        }

        .content-teaser_headline {
            font-size: 40px;
            margin: 0 0 20px 0;
        }

        .content-teaser_text {
            font-size: 18px;
            margin: 0;
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        .content-teaser {
            margin-bottom: 60px;
            position: relative;
            background: #FFF;
            padding: 30px 20px;
            box-shadow: 0px 3px 6px #00000029;
        }

            .content-teaser.left .content-teaser_image {
                left: 0;
            }

            .content-teaser.right .content-teaser_image {
                right: 0;
            }

            .content-teaser.left .content-teaser_content {
                float: right;
            }

            .content-teaser.right .content-teaser_content {
                float: left;
            }

        .content-teaser_image {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding: 0 25%;
        }

            .content-teaser_image img {
                height: 340px;
                width: auto;
            }

        .content-teaser.right .content-teaser_image img {
            transform: translateX(50%);
        }

        .content-teaser.left .content-teaser_image img {
            transform: translateX(-50%);
        }

        .content-teaser_content {
            width: 50%;
        }

        .content-teaser_headline {
            font-size: 40px;
            margin: 0 0 20px 0;
        }

        .content-teaser_text {
            font-size: 18px;
            margin: 0;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        .content-teaser {
            margin-bottom: 60px;
            position: relative;
            background: #FFF;
            padding: 30px 20px;
            box-shadow: 0px 3px 6px #00000029;
        }

            .content-teaser.left .content-teaser_image {
                left: 0;
            }

            .content-teaser.right .content-teaser_image {
                right: 0;
            }

            .content-teaser.left .content-teaser_content {
                float: right;
            }

            .content-teaser.right .content-teaser_content {
                float: left;
            }

        .content-teaser_image {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding: 0 25%;
        }

            .content-teaser_image img {
                height: 340px;
                width: auto;
            }

        .content-teaser.right .content-teaser_image img {
            transform: translateX(50%);
        }

        .content-teaser.left .content-teaser_image img {
            transform: translateX(-50%);
        }

        .content-teaser_content {
            width: 50%;
        }

        .content-teaser_headline {
            font-size: 40px;
            margin: 0 0 20px 0;
        }

        .content-teaser_text {
            font-size: 18px;
            margin: 0;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1440px) {
        .content-teaser {
            margin-bottom: 60px;
            position: relative;
            background: #FFF;
            padding: 30px 20px;
            box-shadow: 0px 3px 6px #00000029;
        }

            .content-teaser.left .content-teaser_image {
                left: 0;
            }

            .content-teaser.right .content-teaser_image {
                right: 0;
            }

            .content-teaser.left .content-teaser_content {
                float: right;
            }

            .content-teaser.right .content-teaser_content {
                float: left;
            }

        .content-teaser_image {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding: 0 25%
        }

            .content-teaser_image img {
                height: 340px;
                width: auto;
            }

        .content-teaser.right .content-teaser_image img {
            transform: translateX(50%);
        }

        .content-teaser.left .content-teaser_image img {
            transform: translateX(-50%);
        }

        .content-teaser_content {
            width: 50%;
        }

        .content-teaser_headline {
            font-size: 40px;
            margin: 0 0 20px 0;
        }

        .content-teaser_text {
            font-size: 18px;
            margin: 0;
        }
    }
</style>

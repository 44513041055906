<template>
    <div class="content-section" :style="getBackgroundColorStyle(backgroundColor)">
        <div class="content-section_inner">
            <div class="center" v-if="headline">
                <h2 v-html="headline" class="content-section_headline"></h2>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContentSection',
        props: ['headline', 'backgroundColor'],
        methods: {
            getBackgroundColorStyle: function (color) {
                return color ? "background-color: " + color + ";" : "";
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .content-section_headline:after, .content-section_headline:before {
        content: "";
        display: block;
        width: 50px;
        position: absolute;
        height: 1px;
        background: #000;
        top: 50%;
        transform: translateY(-50%);
    }

    .content-section_headline:after {
        right: -60px;
    }

    .content-section_headline:before {
        left: -60px;
    }

    .content-section_headline {
        margin: 50px 0;
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 767px) {
        .content-section_inner {
            width: 100%;
        }

        .content-section_headline {
            font-family: 'Abhaya Libre';
            display: inline-block;
            position: relative;
            color: #333;
            font-weight: 700;
            font-size: 40px;
        }

        .content-section {
            padding: 60px 10px;
        }

        .content-section_inner {
            width: 100%;
            margin: 0 auto;
            font-size: 16px;
            line-height: 24px;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        .content-section_inner {
            width: 768px;
        }

        .content-section_headline {
            font-family: 'Abhaya Libre';
            display: inline-block;
            font-size: 80px;
            position: relative;
            color: #333;
            font-weight: 700;
        }

        .content-section {
            padding: 60px 10px;
        }

        .content-section_inner {
            width: 100%;
            margin: 0 auto;
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        .content-section_inner {
            width: 768px;
        }

        .content-section {
            padding: 60px 10px;
        }

        .content-section_inner {
            width: 768px;
            margin: 0 auto;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        .content-section_inner {
            width: 780px;
        }

        .content-section {
            padding: 80px 10px;
        }

        .content-section_inner {
            width: 992px;
            margin: 0 auto;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1440px) {

        .content-section_inner {
            width: 1200px;
            margin: 0 auto;
        }
    }
</style>

<template>
    <div class="screenshot-galery clearfix">
        <div class="screenshot-galery_image" v-for="(image,index) in images" v-bind:key="'image_' + index">
            <img :src="image" @click="openPopup(image)" />
        </div>
        <div class="screenshot-galery_popup" v-if="popupimage" @click="closePopup()">
            <img :src="popupimage" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ScreenshotGalery',
        props: ['images'],
        data: function () {
            return {
                popupimage: null
            }
        },
        methods: {
            openPopup: function (image) {
                this.popupimage = image;
            },
            closePopup: function () {
                this.popupimage = null;
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .screenshot-galery_popup {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        width: 100%;
        height: 100%;
        padding: 10px;
        background: rgba(0,0,0,0.8);
        overflow: auto;
    }

        .screenshot-galery_popup img {
            width: 100%;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            height: auto;
        }

    .screenshot-galery {
    }

    .screenshot-galery_image {
        float: left;
        padding: 10px;
    }

        .screenshot-galery_image img:hover {
            box-shadow: 0px 0px 10px #FFFFFF;
            cursor: pointer;
        }

        .screenshot-galery_image img {
            width: 100%;
            height: auto;
        }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 767px) {
        .screenshot-galery_popup {
            padding: 10px;
        }

        .screenshot-galery_image {
            width: 50%;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        .screenshot-galery_popup {
            padding: 20px;
        }

        .screenshot-galery_image {
            width: 50%;
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        .screenshot-galery_popup {
            padding: 40px;
        }

        .screenshot-galery_image {
            width: 25%;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        .screenshot-galery_popup {
            padding: 60px;
        }

        .screenshot-galery_image {
            width: 25%;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1440px) {
        .screenshot-galery_image {
            width: 25%;
        }
    }
</style>

<template>
  <div id="app">
    <HeaderImage :backgroundImage="HeaderBackground" :logoImage="LogoImage">
    </HeaderImage>
    <ContentSection headline="Synopsis" backgroundColor="#e6eef3">
      <p class="center pt-5">
        You play as Paul, a 35-year-old man, who goes on a strangely unexpected
        journey in the Alps mountains after a fight with his girlfriend Lisa. On
        his path he experiences scary occurrences and encounters misshapen
        creatures, that force Paul to discover the eerie history of the region
        and confront his own childhood trauma.
      </p>
      <div class="clearfix genre-eu-container">
        <div class="content-container-50" style="background-color: #7697af;">
          <b style="color: #FFF;">Genre</b>
          <div class="clearfix">
            <p
              style="width: calc(100% - 75px); float: left; margin: 0; color: #FFF;"
            >
              1st-person single-player story-driven mystery-adventure game with
              psychological horror elements for PC.
            </p>
            <img
              :src="PCLogo"
              width="75"
              style="position: absolute; right:20px; top:50%; transform: translateY(-50%);"
            />
          </div>
        </div>
        <div class="content-container-50 no-padding eu-logo-container">
          <img :src="EuLogo" class="eu-logo" />
        </div>
      </div>
    </ContentSection>
    <ContentSection headline="Characters" backgroundColor="#7697af">
      <ContentTeaser
        headline="Paul"
        text="35 years old man and the game’s protagonist, who the player controls. He lives together with his girlfriend Lisa in a big town next to the Alps. He grew up in an orphanage and does not know who his biological parents are. Paul is creative and possesses musical talents, but his drug use and recurring depressive episodes have hindered his path to success. He works in odd jobs he does not like to sustain himself."
        :image="characters.Character3"
        float="left"
      ></ContentTeaser>
      <ContentTeaser
        headline="Lisa"
        text="A 35 years old woman and Paul’s girlfriend with whom he lives in a big town next to the Alps. She is ambitious and creative and works as a production assistant in a film production company, although she studied literature and tried to become a theatre director. Paul did the music for her first theatre production, and she fell in love with him afterwards. Ever since her best friend had a baby, she always thinks about becoming a mother. Paul and Lisa have a fight at the start of the game, which sends Paul on his journey to the Alps."
        :image="characters.Character2"
        float="right"
      ></ContentTeaser>
      <ContentTeaser
        headline="Alex"
        text="A 29 y/o man Paul’s best buddy and a member of Paul’s grunge band project “Borderline”. His rich parents pay for a private business school where he still has not finished his post gradual studies. He always has the newest and best music equipment, and he pays for the weed he smokes together with Paul before they start playing their music. Once Paul is stranded in the Alps, he calls Alex to help him."
        :image="characters.Character1"
        float="left"
      ></ContentTeaser>
    </ContentSection>
    <ContentSection backgroundColor="#243a51">
      <ScreenshotGalery :images="screenshots"></ScreenshotGalery>
    </ContentSection>
    <ContentSection backgroundColor="#475968" class="mailing-list">
      <div class="mailing-list clearfix">
        <div class="mailing-list_headline">Join our mailing list</div>
        <div class="mailing-list_inputwrap">
          <input
            placeholder="Your email address"
            type="text"
            class="mailing-list_input"
          />
          <button class="mailing-list_button">
            <img :src="NewsletterArrow" />
          </button>
        </div>
      </div>
    </ContentSection>
  </div>
</template>

<script>
import HeaderImage from "./components/HeaderImage.vue";
import ContentSection from "./components/ContentSection.vue";
import ContentTeaser from "./components/ContentTeaser.vue";
import ScreenshotGalery from "./components/ScreenshotGalery.vue";

import Screenshot1 from "./assets/Gameplay-Screenshots/HeimwehScreenshot01.png";
import Screenshot2 from "./assets/Gameplay-Screenshots/HeimwehScreenshot04.png";
import Screenshot3 from "./assets/Gameplay-Screenshots/HeimwehScreenshot07.png";
import Screenshot4 from "./assets/Gameplay-Screenshots/HeimwehScreenshot10.png";
import Screenshot5 from "./assets/Gameplay-Screenshots/HeimwehScreenshot12.png";
import Screenshot6 from "./assets/Gameplay-Screenshots/HeimwehScreenshot17.png";
import Screenshot7 from "./assets/Gameplay-Screenshots/HeimwehScreenshot21.png";
import Screenshot8 from "./assets/Gameplay-Screenshots/HeimwehScreenshot24.png";

import Arrow from "./assets/arrow.png";
import Character1 from "./assets/Character-Descriptions/heimweh_char_sheet_alex.png";
import Character2 from "./assets/Character-Descriptions/heimweh_char_sheet_lisa.png";
import Character3 from "./assets/Character-Descriptions/heimweh_char_sheet_paul.png";

import HeaderBackground from "./assets/Logo-and-Title/HeimwehTitle_Background.jpg";

import LogoImage from "./assets/Logo-and-Title/Logo1.png";
import PCLogo from "./assets/About-the-project/PC_Logo2.png";
import EuLogo from "./assets/About-the-project/BMVI_Foerderlogo_englisch_NEU.png";

export default {
  name: "App",
  data: function() {
    return {
      HeaderBackground: HeaderBackground,
      LogoImage: LogoImage,
      PCLogo: PCLogo,
      EuLogo: EuLogo,
      characters: {
        Character1: Character1,
        Character2: Character2,
        Character3: Character3,
      },
      NewsletterArrow: Arrow,
      screenshots: [
        Screenshot1,
        Screenshot2,
        Screenshot3,
        Screenshot4,
        Screenshot5,
        Screenshot6,
        Screenshot7,
        Screenshot8,
      ],
    };
  },
  components: {
    HeaderImage,
    ContentSection,
    ContentTeaser,
    ScreenshotGalery,
  },
};
</script>

<style>
/* sinhala */
@font-face {
  font-family: "Abhaya Libre";
  font-style: normal;
  font-weight: 400;
  src: local("Abhaya Libre Regular"), local("AbhayaLibre-Regular"),
    url(https://fonts.gstatic.com/s/abhayalibre/v6/e3tmeuGtX-Co5MNzeAOqinEQYUnXkvc.woff2)
      format("woff2");
  unicode-range: U+0964-0965, U+0D82-0DF4, U+200C-200D, U+25CC;
}
/* latin-ext */
@font-face {
  font-family: "Abhaya Libre";
  font-style: normal;
  font-weight: 400;
  src: local("Abhaya Libre Regular"), local("AbhayaLibre-Regular"),
    url(https://fonts.gstatic.com/s/abhayalibre/v6/e3tmeuGtX-Co5MNzeAOqinEQcknXkvc.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Abhaya Libre";
  font-style: normal;
  font-weight: 400;
  src: local("Abhaya Libre Regular"), local("AbhayaLibre-Regular"),
    url(https://fonts.gstatic.com/s/abhayalibre/v6/e3tmeuGtX-Co5MNzeAOqinEQfEnX.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.keyfeatures-slider {
  position: relative;
}

.keyfeatures-slider_content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 20px;
}

.keyfeatures-slider_content h2,
.keyfeatures-slider_content p {
  color: #fff;
  text-shadow: 2px 2px 2px #000;
}

.keyfeatures-slider_content h2 {
  font-size: 50px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 20px;
}

.keyfeatures-slider_content p {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.2;
}

@media only screen and (min-width: 768px) {
  .keyfeatures-slider_content h2 {
    font-size: 100px;
    margin-bottom: 60px;
  }

  .keyfeatures-slider_content p {
    font-size: 40px;
  }
}

.mailing-list {
  padding-right: 90px;
}

.mailing-list_headline {
  float: left;
  width: 50%;
  text-align: right;
  padding-right: 50px;
  color: #fff;
  font-size: 40px;
  line-height: 48px;
}

.mailing-list_inputwrap {
  float: left;
  width: 50%;
  position: relative;
}

.mailing-list_input {
  width: 100%;
  height: 41px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  outline: none;
  padding: 0 10px;
  line-height: 41px;
  font-size: 16px;
  font-family: "Abhaya Libre";
}

.mailing-list_button {
  position: absolute;
  right: 0;
  top: 0;
  width: 41px;
  height: 41px;
  background: none;
  outline: none;
  border: none;
}

img {
  display: inline-block;
  max-width: 100%;
}

.clearfix {
  display: block;
}

.clearfix:after,
.clearfix:before {
  content: "";
  clear: both;
  display: block;
  width: 0;
  height: 0;
  line-height: 0;
}

* {
  box-sizing: border-box;
}

.center {
  text-align: center;
}

body {
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  font-family: "Abhaya Libre";
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {
  .mailing-list_headline {
    width: 100%;
    text-align: left;
    padding-right: 20px;
    color: #fff;
    font-size: 20px;
    margin: 10px 0;
    line-height: 34px;
  }

  .mailing-list_input {
    width: 100%;
  }

  .mailing-list_inputwrap {
    width: 292px;
    position: relative;
    margin: 10px 0;
  }

  .content-container-50 {
    width: 100%;
    padding: 30px 20px;
    font-size: 18px;
    line-height: 27px;
    margin: 10px 0;
    position: relative;
  }

  .content-container-50.no-padding {
    padding: 0;
  }

  .content-container-50 b {
    font-size: 24px;
  }

  .content-container-50.purple {
    background: #4f2266;
    color: #fff;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .content-container-50 {
    width: 100%;
    padding: 30px 20px;
    font-size: 18px;
    line-height: 27px;
    position: relative;
  }

  .content-container-50.no-padding {
    padding: 0;
  }

  .content-container-50 b {
    font-size: 24px;
  }

  .content-container-50.purple {
    background: #4f2266;
    color: #fff;
  }
}

@media only screen and (max-width: 992px) {
  .eu-logo {
    margin-left: 160px;
  }
}

@media only screen and (max-width: 745px) {
  .eu-logo {
    margin-left: 70px;
  }
}

@media only screen and (max-width: 645px) {
  .eu-logo {
    margin-left: 0px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .content-container-50 {
    width: 50%;
    float: left;
    padding: 30px 20px;
    font-size: 18px;
    line-height: 27px;
    position: relative;
  }

  .content-container-50.no-padding {
    padding: 0;
  }

  .content-container-50 b {
    font-size: 24px;
  }

  .content-container-50.purple {
    background: #4f2266;
    color: #fff;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .eu-logo {
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1440px) {
  .eu-logo {
  }
}

.genre-eu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px 0px 0px;
}

.eu-logo-container {
  float: none;
}
</style>
